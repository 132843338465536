import get from 'lodash/get';
import store from '../../index';
import {
  ContentType,
  REGENERATION_LIMIT,
  toneStyleOptions,
} from '../../../../constants/common';
import { getGeneratedContent } from '../../../../services/Content';
import { ParamsUsed } from '../../../../types/store/markettingContentGeneration/majorProcessActions';
import { getContentGroupId, getSessionId } from '../../../../utils/commonUtils';
import {
  getMappedOption,
  getParamsUsed,
  resetTranslationState,
} from '../../helpers/common';
import { contentListStateSegment } from '../../stateSegments/contentListSegment';
import { moduleContentsStateSegment } from '../../stateSegments/emailModulesStateSegment';
import { getTransformedEmailModuleData } from '../../../../utils/apiResponseTransformers';
import {
  GeneratedModuleContent,
  RecommendedImage,
} from '../../../../types/Content';
import {
  ContentImagesHashMap,
  contentImagesStateSegment,
} from '../../stateSegments/contentImagesStateSegment';
import { contentTranslationStateSegment } from '../../stateSegments/translationSegment';

export const fetchContent = async (
  paramsUsed: ParamsUsed,
  contentType: ContentType,
  contentId: string,
  mapKey: string,
  destinationFormat = ''
) => {
  resetTranslationState();

  store.set(contentListStateSegment, (prev) => {
    return {
      ...prev,
      isGeneratingContent: true,
      retryPromptParams: paramsUsed,
    };
  });

  const { success, data } = await getGeneratedContent({
    sessionId: getSessionId(),
    contentGroupId: getContentGroupId(),
    contentId,
    action: paramsUsed.currentSelectedAction,
  });

  if (success && data) {
    if (
      contentType.toLowerCase() === ContentType.EMAIL.toLowerCase() ||
      destinationFormat.toLowerCase() === ContentType.EMAIL.toLowerCase()
    ) {
      const moduleContents = new Map(store.get(moduleContentsStateSegment));
      const module = moduleContents.get(mapKey);

      if (module) {
        const generatedContent = get(data, 'generatedContent', []);
        const translatedContent = get(data, 'translatedContent', []);
        // const imagesRecommended = get(data, 'imagesRecommended', []);
        const primaryLanguage = paramsUsed.currentSelectedLanguage;

        store.set(contentListStateSegment, (prev) => {
          const emailModuleContent = getTransformedEmailModuleData(
            generatedContent as GeneratedModuleContent[]
          );

          const contents = [] as unknown[];

          emailModuleContent?.forEach((content) => {
            const { id, score, versionSequence } = content;

            contents.push({
              [primaryLanguage]: {
                id,
                score,
                content,
              },
              versionSequence,
              paramsUsed: getParamsUsed(paramsUsed),
              regenerationLimit: REGENERATION_LIMIT - generatedContent.length,
              apiPayloadUsed: module?.generatePayload ?? null,
            });

            generatedContent.forEach((item) => {
              item.imagesRecommend?.forEach((imageRecommend) => {
                const { optionId, recommendedImages } = imageRecommend;

                if (recommendedImages && recommendedImages.length) {
                  store.set(contentImagesStateSegment, (state) => {
                    const translatedModuleContent = translatedContent.find(
                      (traContent) => traContent.id === content.id
                    );
                    let translatedImages: RecommendedImage[] = [];
                    if (translatedModuleContent) {
                      const { imagesRecommend } = translatedModuleContent;
                      const option = imagesRecommend?.find(
                        (imageOptions) => imageOptions.optionId === optionId
                      );
                      translatedImages = option?.recommendedImages || [];
                    }
                    return {
                      ...state,
                      [content.id]: {
                        ...state[content.id],
                        [optionId]: {
                          isLoading: false,
                          imagesReferenceFrame: '',
                          errorFetchingImages: false,
                          images: recommendedImages.map((image) => ({
                            name: image.imageName,
                            altText: image.caption,
                            frameId: optionId,
                            id: image.imageId, // Generate a unique ID
                            caption: image.caption || '',
                            translatedCaption:
                              translatedImages?.find(
                                (translatedImageData) =>
                                  translatedImageData.imageId === image.imageId
                              )?.caption || '',
                          })),
                        },
                      },
                    } as ContentImagesHashMap;
                  });
                }
              });
            });
          });

          let sortedContents = contents.sort(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (a: any, b: any) => a.versionSequence - b.versionSequence
          );

          const versionSequenceMapping: Record<string, number> = {};
          sortedContents.forEach((content, index) => {
            const { id } = get(content, `${primaryLanguage}`, { id: '' });
            if (id) versionSequenceMapping[id] = index;
          });

          sortedContents = sortedContents.map((content, index) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const contentItem = content as Record<string, any>;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { apiPayloadUsed, paramsUsed: contentItemParams } =
              contentItem as Record<
                string,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                any
              >;
            const refineConfigOption = module.refineConfig[index];
            if (refineConfigOption) {
              contentItem.apiPayloadUsed = {
                ...apiPayloadUsed,
                inclusions: refineConfigOption.inclusions,
                exclusions: refineConfigOption.exclusions,
                operationType: refineConfigOption.operationType,
                toneStyle: refineConfigOption.toneStyle,
              };

              contentItem.paramsUsed = {
                ...contentItemParams,
                inclusions: refineConfigOption.inclusions.join(', '),
                exclusions: refineConfigOption.exclusions,
                toneStyle: getMappedOption(
                  toneStyleOptions,
                  refineConfigOption.toneStyle
                ),
              };
              contentItem.isRefineApplied = refineConfigOption.isRefineApplied;
            }
            return content;
          });

          const translatedEmailModulesContent = getTransformedEmailModuleData(
            translatedContent as GeneratedModuleContent[]
          );

          translatedEmailModulesContent?.forEach((traContent) => {
            const { id: translatedContentId } = traContent;

            store.set(contentTranslationStateSegment, (state) => {
              const { translationConfig } = module;
              return {
                ...state,
                isTranslatingContent: false,
                map: {
                  ...state.map,
                  [translatedContentId]: {
                    langCode:
                      translationConfig[
                        versionSequenceMapping[translatedContentId]
                      ] || '',
                    data: {
                      id: translatedContentId,
                      score: {
                        value: traContent?.score?.value,
                      },
                      content: traContent,
                    },
                    isFetched: true,
                  },
                },
              };
            });
          });

          return {
            ...prev,
            errorGeneratingContent: false,
            errorMessage: '',
            isGeneratingContent: false,
            contentList: sortedContents as never[],
          };
        });
      }
    }
  } else {
    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        isGeneratingContent: false,
        errorGeneratingContent: true,
        errorMessage: 'There has been an error in generating content',
      };
    });
  }
};
